<template>
  <div class="information">
    <div class="bread">
      <i class="el-icon-arrow-left" @click="goback()"></i>
      大会信息
    </div>
    <div class="head"></div>
    <div class="content" :style="isheight?'line-height:18px':''">
      <p>
        近年来，我国透析患者数量以13%的速度逐年递增，统计数据显示，截至2022年底，我国大陆地区登记透析总人数已突破百万，与2012年相比，十年间透析患者总人数增加了3.2倍，患者平均生存年限提升了2.5倍，整体需求飞速增长
      </p>
      <p>
        与之不匹配的是，我国透析资源分布不均匀，透析中心数量增长远低于患者数量增长，整体市场供不应求。现有透析中心以中大型为主，集中在三级和二级医院，床位已趋于饱和，而独立透析中心数量不足5%，无法补足缺口。从2015年至今国家多次下达政策鼓励基层医院和社区服务中心开展维持性透析，公立基层透析有巨大的发展空间和优势。纵观国际，社区化、小型化是血透中心发展的必然趋势。
      </p>
      <p>
        城市社区服务中心是解决血透市场供不应求的最佳捷径，社区是距离患者最近的医疗单位，既有设备齐全的检验和治疗设备，又有经验丰富的全科医生，同时还是大型医院医联体的终端触角，开展社区血透也是越来越多的透析患者的呼声。
        鉴于此，北京社区健康促进会于2023年3月29日举办首届“基层透析发展论坛”，响应国家分级诊疗政策，加强基层医疗卫生服务体系建设，推进慢病下沉基层，提升透析患者的医疗服务水平和生存质量。
      </p>
      <p>
        诚挚邀请北京市各区卫健委、社管中心、北京市基层医疗卫生服务机构管理者及相关人员参加此次会议。
      </p>
      <p>谨代表大会组委会热忱期盼与您相会！</p>
      <h3 class="pdtop">基层透析发展论坛组委会</h3>
      <h3>2023年3月</h3>
    </div>
    <div class="bg3"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        isheight:false
    };
  },
  created() {
      if (document.documentElement.clientHeight < 700) {
          this.isheight = true
      }
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.information {
  color: #fff;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  position: relative;
  .bread {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 17px;
      font-weight: 600;
    }
  }
  .head {
    height: 95px;
    background: url("../../assets/image2/head.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    z-index: 999;
    padding: 25px 15px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 20px;
    p {
      text-indent: 1rem;
      font-weight: 300;
    }
    .pdtop {
      padding-top: 20px;
    }
    h3 {
      text-align: right;
    }
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
